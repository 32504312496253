import { RouteConfig } from "vue-router";

const TextbookRoute: Array<RouteConfig> = [
  {
    path: "/admin/textbook",
    component: () => import("@/views/admin/textbook/Index.vue")
  },
  {
    path: "/admin/textbook/add",
    component: () => import("@/views/admin/textbook/Add.vue")
  },
  {
    path: "/admin/textbook/edit",
    component: () => import("@/views/admin/textbook/Edit.vue")
  }
];

export { TextbookRoute };