import axios from "axios";

import { LangType } from "./LangType";

import { Log } from "@/util";

class LangManager {
  public static get instance(): LangManager {
    if (!LangManager.sInstance) {
      LangManager.sInstance = new LangManager();
    }
    return LangManager.sInstance;
  }

  private static sInstance: LangManager;

  private type: LangType = LangType.EN;

  public setType(type: LangType): void {
    this.type = type;
  }

  public getType(): LangType {
    return this.type;
  }

  public getText(key: string, page = "misc"): string {
    const textHash = this.getTextHashFromSession();

    return textHash[page] && textHash[page][key]
      ? textHash[page][key]
      : "[key] not exist.";
  }

  public getTextHashByPage(
    page: string
  ): { [page: string]: { [key: string]: string } } {
    const pageTextHash: { [page: string]: { [key: string]: string } } = {};

    const textHash = this.getTextHashFromSession();

    pageTextHash["misc"] = textHash["misc"];
    if (textHash[page]) {
      pageTextHash[page] = textHash[page];
    }

    return pageTextHash;
  }

  public async loadMiscLangMap(): Promise<void> {
    const textHash: { [page: string]: { [key: string]: string } } = {};
    textHash["misc"] = {};

    const url = `/assets/lang/${this.type}/misc.json`;

    await axios
      .get(url)
      .then(response => {
        textHash["misc"] = response.data as { [key: string]: string };
      })
      .catch(error => {
        Log.error(error);
      });

    const textHashString = JSON.stringify(textHash);
    sessionStorage.setItem("textHash", textHashString);
  }

  public async loadPageLangMap(name: string): Promise<void> {
    const textHash = this.getTextHashFromSession();

    if (!textHash[name]) {
      textHash[name] = {};

      const url = `/assets/lang/${this.type}/${name}.json`;

      await axios
        .get(url)
        .then(response => {
          textHash[name] = response.data as { [key: string]: string };
        })
        .catch(error => {
          Log.error(error);
        });

      const textHashString = JSON.stringify(textHash);
      sessionStorage.setItem("textHash", textHashString);
    }
  }

  private getTextHashFromSession(): {
    [page: string]: { [key: string]: string };
  } {
    let textHash: { [page: string]: { [key: string]: string } } = {};

    const textHashString = sessionStorage.getItem("textHash");
    if (textHashString !== null) {
      textHash = JSON.parse(textHashString);
    }

    return textHash;
  }
}

const langManager = LangManager.instance;

export { langManager as LangManager };
