import { RouteConfig } from "vue-router";

export const HomeworkRoute: Array<RouteConfig> = [
  {
    path: "/teacher/homework/question",
    component: () => import("@/views/ap/teacher/homework/question.vue")
  },
  {
    path: "/teacher/homework/question/add",
    component: () => import("@/views/ap/teacher/homework/QuestionAdd.vue")
  },
  {
    path: "/teacher/homework/question/edit",
    component: () => import("@/views/ap/teacher/homework/QuestionEdit.vue")
  },
  {
    path: "/teacher/homework/paper",
    component: () => import("@/views/ap/teacher/homework/Paper.vue")
  },
  {
    path: "/teacher/homework/paper/add",
    component: () => import("@/views/ap/teacher/homework/PaperAdd.vue")
  },
  {
    path: "/teacher/homework/paper/edit",
    component: () => import("@/views/ap/teacher/homework/PaperEdit.vue")
  },
  {
    path: "/teacher/homework/paper/info",
    component: () => import("@/views/ap/teacher/homework/PaperInfo.vue")
  },
  {
    path: "/teacher/homework/verify",
    component: () => import("@/views/ap/teacher/homework/Verify.vue")
  },
  {
    path: "/teacher/homework/verify/info",
    component: () => import("@/views/ap/teacher/homework/VerifyInfo.vue")
  },
];