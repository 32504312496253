import { RouteConfig } from "vue-router";

const SchoolRoute: Array<RouteConfig> = [
  {
    path: "/admin/school",
    component: () => import("@/views/admin/school/Index.vue")
  },
  {
    path: "/admin/school/add",
    component: () => import("@/views/admin/school/Add.vue")
  },
  {
    path: "/admin/school/edit",
    component: () => import("@/views/admin/school/Edit.vue")
  }
];

export { SchoolRoute }; 