import { Auth } from "./Auth";

export class AuthAdmin implements Auth {
  private auth: { [key: string]: string } = { AdminToken: "" };
  private expireIn = -1;

  public setAuth(token: string, expireIn?: number): void {
    this.auth.AdminToken = this.auth.AdminToken + token;

    if (expireIn) {
      this.expireIn = expireIn;
    }
  }

  public getAuth(): { [key: string]: string } {
    return this.auth;
  }

  public getAuthKey(): string {
    return "AdminToken";
  }

  public doesTokenExpire(): boolean {
    const dateTime = Date.now();
    const timestamp = Math.floor(dateTime / 1000);

    return this.expireIn + 10 >= timestamp;
  }
}
