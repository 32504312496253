import { Auth } from "./Auth";
import { AuthType } from "./AuthType";
import { AuthBase } from "./AuthBase";
import { AuthJWT } from "./AuthJWT";
import { AuthAdmin } from "./AuthAdmin";

export class AuthSimpleFactory {
  public static create(authType: AuthType): Auth {
    switch (authType) {
      case AuthType.Base:
        return new AuthBase();
      case AuthType.JWT:
        return new AuthJWT();
      case AuthType.Admin:
        return new AuthAdmin();
      default:
        return new AuthJWT();
    }
  }
}
