






import { Component, Vue } from "vue-property-decorator";

import { AccountModel } from "@/models";
import { Cookie } from "@/util";

@Component<App>({
  components: {}
})
export default class App extends Vue {
    private time = 600000;
    private interval!: null | ReturnType<typeof setInterval>;

    // public created() {
        // this.$root.$on("setLogin", this.setLogin);
        // this.$root.$on("setLogout", this.setLogout);
    // }

    // private setLogin() {
    //     this.interval = setInterval(() => {
    //       // InfoModel.setLoginTime();
    //     }, this.time);
    // }

    // private setLogout() {
    //   if (this.interval) {
    //     clearInterval(this.interval);
    //   }
    // }

}
