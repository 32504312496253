import { AxiosResponse, Method } from "axios";

import { APIType } from "./api/APIType";
import { ClientAPI } from "./api/ClientAPI";
import { AuthType } from "./auth/AuthType";

import { AccountModel } from "@/models";

class Network {
  private static sInstance: Network;

  public static get instance(): Network {
    if (!Network.sInstance) {
      Network.sInstance = new Network();
    }

    return Network.sInstance;
  }

  private clientAPI = new ClientAPI();

  // -------------------------------- WebSocket --------------------------------

  public initWebSocket(): void {
    // TODO: initWebSocket
  }

  public connect(): void {
    // TODO: connect
  }

  public disconnect(): void {
    // TODO: disconnect
  }

  // ----------------------------------- API -----------------------------------

  public setBaseURL(baseURL: string): void {
    this.clientAPI.setBaseURL(baseURL);
  }

  public setAPI(type: APIType): void {
    this.clientAPI.setAPI(type);
  }

  public setAuth(type: AuthType, value: string, expireIn?: number): void {
    this.clientAPI.setAuth(type);
    this.clientAPI.setToken(value, expireIn);
  }

  public removeToken(): void {
    this.clientAPI.removeToken();
  }

  public authCheck(type: number): boolean {
    let token = '';
    switch (type) {
      case 1:
        token = window.localStorage.getItem('adminToken') as string;
      break;
      case 2:
        token = window.localStorage.getItem('teacherToken') as string;
      break;
      case 3:
        token = window.localStorage.getItem('studentToken') as string;
      break;
      default:
    }
    if (token === null) {
      return false;
    }
    return true;
  }

  public sendAPI(
    uri: string,
    method: Method,
    data?: unknown
  ): Promise<AxiosResponse<unknown>> {
    return this.clientAPI.sendAPI(uri, method, data);
  }

  public uploadFile(
    uri: string,
    data: FormData
  ): Promise<AxiosResponse<unknown>> {
    return this.clientAPI.uploadFile(uri, data);
  }

  public downloadFile(uri: string, data?: unknown): Promise<void> {
    return this.clientAPI.downloadFile(uri, data);
  }

  public downloadFileFromS3(url: string, data?: unknown): Promise<void> {
    return this.clientAPI.downloadFileFromS3(url, data);
  }

  public getImageSrcByPost(uri: string, data?: unknown): Promise<string> {
    return this.clientAPI.getImageSrcByPost(uri, data);
  }
}

const network = Network.instance;

export { network as Network };
