import { RouteConfig } from "vue-router";

import { Group } from "./Group";

class RouterManager {
  public static get instance(): RouterManager {
    if (!RouterManager.sInstance) {
      RouterManager.sInstance = new RouterManager();
    }
    return RouterManager.sInstance;
  }

  private static sInstance: RouterManager;

  private allRoute: Array<RouteConfig> = [];

  public getAllRoute(): Array<RouteConfig> {
    return this.allRoute;
  }

  public setAllRoute(): void {
    this.allRoute = Array.prototype.concat.apply([], Group);
  }
}

const routerManager = RouterManager.instance;

export { routerManager as RouterManager };
