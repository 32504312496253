import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import qs from "qs";

import { Auth } from "../auth/Auth";
import { AuthType } from "../auth/AuthType";
import { API } from "./API";

export class APIJSON implements API {
  private auth!: { type: AuthType; instance: Auth };

  public setAuth(auth: { type: AuthType; instance: Auth }): void {
    this.auth = auth;
  }

  public getAuth(): { type: AuthType; instance: Auth } {
    return this.auth;
  }

  public setToken(value: string, expireIn?: number): void {
    this.auth.instance.setAuth(value, expireIn);

    const auth = this.auth.instance.getAuth();
    Object.assign(axios.defaults.headers.common, auth);
  }

  public removeToken(): void {
    const key = this.auth.instance.getAuthKey();
    delete axios.defaults.headers.common[key];
  }

  public getToken(): { [key: string]: string } {
    return this.auth.instance.getAuth();
  }

  public doesTokenExpire(): boolean {
    return this.auth.instance.doesTokenExpire();
  }

  public async call(
    url: string,
    method: Method,
    data?: unknown
  ): Promise<AxiosResponse<unknown>> {
    let apiResponse!: AxiosResponse<unknown>;

    let requestBody = "";
    if (data) {
      requestBody = qs.stringify(data as object);
    }

    const request: AxiosRequestConfig = {
      url: url,
      method: method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      responseType: "json",
      data: requestBody
    };
    // console.log(axios.defaults.headers.common);
    await axios(request)
      .then(response => {
        apiResponse = response;
      })
      .catch(error => {
        throw error;
      });

    return apiResponse;
  }

  public async upload(
    url: string,
    data: FormData
  ): Promise<AxiosResponse<unknown>> {
    let apiResponse!: AxiosResponse<unknown>;

    const request: AxiosRequestConfig = {
      url: url,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      responseType: "json",
      data: data
    };

    await axios(request)
      .then(response => {
        apiResponse = response;
      })
      .catch(error => {
        throw error;
      });

    return apiResponse;
  }

  public async download(url: string, data?: unknown): Promise<void> {
    let requestBody!: object;
    if (data) {
      const object = data as object;
      requestBody = object;
    }

    const request: AxiosRequestConfig = {
      url: url,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      responseType: "blob",
      data: requestBody ? requestBody : undefined
    };

    await axios(request)
      .then(response => {
        const fileName = response.headers["content-disposition"].match(
          /filename=(.*)/
        )[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        throw error;
      });
  }

  public async downloadFromS3(url: string, data?: unknown): Promise<void> {
    let requestBody!: object;
    if (data) {
      const object = data as object;
      requestBody = object;
    }

    const request: AxiosRequestConfig = {
      url: url,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      responseType: "blob",
      data: requestBody ? requestBody : undefined
    };

    await axios(request)
      .then(response => {
        const urlBlock = url.split("/");
        const fileName = urlBlock[urlBlock.length - 1];
        const objURL = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = objURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        throw error;
      });
  }

  public async getImageSrc(url: string, data?: unknown): Promise<string> {
    let imageSrc = "";

    let requestBody = "";
    if (data) {
      requestBody = qs.stringify(data as object);
    }

    const request: AxiosRequestConfig = {
      url: url,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      responseType: "blob",
      data: requestBody
    };

    await axios(request)
      .then(response => response.data as Blob)
      .then(blob => URL.createObjectURL(blob))
      .then(src => {
        imageSrc = src;
      })
      .catch(error => {
        throw error;
      });

    return imageSrc;
  }
}
