import { RouteConfig } from "vue-router";

const StudentRoute: Array<RouteConfig> = [
  {
    path: "/admin/student",
    component: () => import("@/views/admin/student/Index.vue")
  },
  {
    path: "/admin/student/add",
    component: () => import("@/views/admin/student/Add.vue")
  },
  {
    path: "/admin/student/edit",
    component: () => import("@/views/admin/student/Edit.vue")
  },
  {
    path: "/admin/student/learning",
    component: () => import("@/views/admin/student/Learning.vue")
  }
];

export { StudentRoute };