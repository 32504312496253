import { RouteConfig } from "vue-router";

// main
import { APMainRoute} from "./main/index";
// teacher
import { LoginRoute } from "./teacher/Login";
import { TeacherRoute } from "./teacher/Teacher";
import { ClassRoute } from "./teacher/Class";
import { TextbookRoute } from "./teacher/Textbook";
import { StudentRoute } from "./teacher/Student";
import { HomeworkRoute } from "./teacher/Homework";
import { QuizRoute } from "./teacher/Quiz";
import { RollcallRoute } from "./teacher/Rollcall";
import { lnstructionsRoute } from "./teacher/lnstructions";
// student
import { STLoginRoute } from "./student/Login";
import { STStudentRoute } from "./student/Student";
import { STLearningRoute } from "./student/Learning";
import { STClassRoute } from "./student/Class";
import { STQuizRoute } from "./student/Quiz";
import { STHomeworkRoute } from "./student/Homework";
import { STVideoRoute } from "./student/Video";
import { STRollcallRoute } from "./student/Rollcall";
import { STlnstructionsRoute } from "./student/lnstructions";

export const ApplicationRouteRoute: Array<RouteConfig> = [
  // main
  ...APMainRoute,

  // teacher
  ...LoginRoute,
  ...ClassRoute,
  ...TextbookRoute,
  ...TeacherRoute,
  ...StudentRoute,
  ...HomeworkRoute,
  ...QuizRoute,
  ...RollcallRoute,
  ...lnstructionsRoute,

  // student
  ...STLoginRoute,
  ...STStudentRoute,
  ...STLearningRoute,
  ...STClassRoute,
  ...STQuizRoute,
  ...STHomeworkRoute,
  ...STVideoRoute,
  ...STRollcallRoute,
  ...STlnstructionsRoute
];
