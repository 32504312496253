import { RouteConfig } from "vue-router";

const RollcallRoute: Array<RouteConfig> = [
  {
    path: "/admin/rollcall",
    component: () => import("@/views/admin/rollcall/Index.vue")
  },
  {
    path: "/admin/rollcall/add",
    component: () => import("@/views/admin/rollcall/Add.vue")
  },
  {
    path: "/admin/rollcall/record",
    component: () => import("@/views/admin/rollcall/Record.vue")
  }
];

export { RollcallRoute };