import { RouteConfig } from "vue-router";

const ClassRoute: Array<RouteConfig> = [
  {
    path: "/admin/class",
    component: () => import("@/views/admin/class/Index.vue"),
  },
  {
    path: "/admin/class/add",
    component: () => import("@/views/admin/class/Add.vue"),
  },
  {
    path: "/admin/class/edit",
    component: () => import("@/views/admin/class/Edit.vue"),
  },
  {
    path: "/admin/class/student",
    component: () => import("@/views/admin/class/Student.vue"),
  }
];

export { ClassRoute };