class Cookie {
  public static get instance(): Cookie {
    if (!Cookie.sInstance) {
      Cookie.sInstance = new Cookie();
    }
    return Cookie.sInstance;
  }

  private static sInstance: Cookie;

  public getCookie(key: string) {
    const reg = new RegExp(`(^| )${key}=([^;]*)(;|$)`);
    const matches = document.cookie.match(reg);
    if (matches) {
      return matches[2];
    } else {
      return null;
    }
  }

  public setCookie(key: string, value: string, path: string, expireDays?: number) {
    const expDate = new Date();

    // Set it expire in 1 days
    expDate.setTime(expDate.getTime() + (1 * 24 * 60 * 60 * 1000));

    document.cookie =
      key +
      "=" +
      value +
      ";expires=" + expDate.toUTCString()+"; path=" + path;
  }

  public delCookie(key: string, path: string) {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);

    const value = this.getCookie(key);
    if (value != null) {
      document.cookie = key + "=" + value + ";expires=" + exp.toUTCString()+"; path=" + path;
    }
  }
}

const cookie = Cookie.instance;

export { cookie as Cookie };
