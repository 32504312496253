import { RouteConfig } from "vue-router";

export const StudentRoute: Array<RouteConfig> = [
  {
    path: "/teacher/student",
    component: () => import("@/views/ap/teacher/student/Index.vue")
  },
  {
    path: "/teacher/student/info",
    component: () => import("@/views/ap/teacher/student/Info.vue")
  },
  {
    path: "/teacher/student/learning",
    component: () => import("@/views/ap/teacher/student/Learning.vue")
  },
];