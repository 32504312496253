import { RouteConfig } from "vue-router";

export const QuizRoute: Array<RouteConfig> = [
  {
    path: "/teacher/quiz/question",
    component: () => import("@/views/ap/teacher/quiz/Question.vue")
  },
  {
    path: "/teacher/quiz/question/add",
    component: () => import("@/views/ap/teacher/quiz/QuestionAdd.vue")
  },
  {
    path: "/teacher/quiz/question/edit",
    component: () => import("@/views/ap/teacher/quiz/QuestionEdit.vue")
  },
  {
    path: "/teacher/quiz/paper",
    component: () => import("@/views/ap/teacher/quiz/Paper.vue")
  },
  {
    path: "/teacher/quiz/paper/add",
    component: () => import("@/views/ap/teacher/quiz/PaperAdd.vue")
  },
  {
    path: "/teacher/quiz/paper/edit",
    component: () => import("@/views/ap/teacher/quiz/PaperEdit.vue")
  },
  {
    path: "/teacher/quiz/paper/info",
    component: () => import("@/views/ap/teacher/quiz/PaperInfo.vue")
  },
  {
    path: "/teacher/quiz/result",
    component: () => import("@/views/ap/teacher/quiz/Result.vue")
  },
  {
    path: "/teacher/quiz/result/info",
    component: () => import("@/views/ap/teacher/quiz/ResultInfo.vue")
  },
];