import { RouteConfig } from "vue-router";

export const TeacherRoute: Array<RouteConfig> = [
  {
    path: "/teacher/teacher",
    component: () => import("@/views/ap/teacher/teacher/Index.vue")
  },
  {
    path: "/teacher/teacher/edit",
    component: () => import("@/views/ap/teacher/teacher/Edit.vue")
  },
];