import { API } from "./API";
import { APIType } from "./APIType";
import { APIJSON } from "./APIJSON";
import { APIPB } from "./APIPB";

export class APISimpleFactory {
  public static create(apiType: APIType): API {
    switch (apiType) {
      case APIType.JSON:
        return new APIJSON();
      case APIType.PB:
        return new APIPB();
      default:
        return new APIJSON();
    }
  }
}
