import { RouteConfig } from "vue-router";

const AccountRoute: Array<RouteConfig> = [
  {
    path: "/admin/account",
    component: () => import("@/views/admin/account/Index.vue"),
  },
  {
    path: "/admin/account/add",
    component: () => import("@/views/admin/account/Add.vue"),
  },
  {
    path: "/admin/account/edit",
    component: () => import("@/views/admin/account/Edit.vue"),
  }
];

export { AccountRoute };