import { RouteConfig } from "vue-router";

export const RollcallRoute: Array<RouteConfig> = [
  {
    path: "/teacher/rollcall",
    component: () => import("@/views/ap/teacher/rollcall/Index.vue")
  },
  {
    path: "/teacher/rollcall/add",
    component: () => import("@/views/ap/teacher/rollcall/Add.vue")
  },
  {
    path: "/teacher/rollcall/record",
    component: () => import("@/views/ap/teacher/rollcall/Record.vue")
  },
];