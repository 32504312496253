import { RouteConfig } from "vue-router";

export const STQuizRoute: Array<RouteConfig> = [
  {
    path: "/student/quiz",
    component: () => import("@/views/ap/student/quiz/Index.vue")
  },
  {
    path: "/student/quiz/quiz",
    component: () => import("@/views/ap/student/quiz/Quiz.vue")
  },
  {
    path: "/student/quiz/result",
    component: () => import("@/views/ap/student/quiz/Result.vue")
  },
  {
    path: "/student/quiz/result/info",
    component: () => import("@/views/ap/student/quiz/ResultInfo.vue")
  },
];