import { RouteConfig } from "vue-router";

const HomeworkRoute: Array<RouteConfig> = [
  {
    path: "/admin/homework/question",
    component: () => import("@/views/admin/homework/Question.vue"),
  },
  {
    path: "/admin/homework/question/add",
    component: () => import("@/views/admin/homework/QuestionAdd.vue"),
  },
  {
    path: "/admin/homework/question/edit",
    component: () => import("@/views/admin/homework/QuestionEdit.vue"),
  },
  {
    path: "/admin/homework/paper",
    component: () => import("@/views/admin/homework/Paper.vue"),
  },
  {
    path: "/admin/homework/paper/add",
    component: () => import("@/views/admin/homework/PaperAdd.vue"),
  },
  {
    path: "/admin/homework/paper/edit",
    component: () => import("@/views/admin/homework/PaperEdit.vue"),
  },
  {
    path: "/admin/homework/paper/info",
    component: () => import("@/views/admin/homework/PaperInfo.vue"),
  },
  {
    path: "/admin/homework/verify",
    component: () => import("@/views/admin/homework/Verify.vue"),
  },
  {
    path: "/admin/homework/verify/info",
    component: () => import("@/views/admin/homework/VerifyInfo.vue"),
  },
  {
    path: "/admin/homework/audio",
    component: () => import("@/views/admin/homework/Audio.vue"),
  },
];

export { HomeworkRoute };