import { AxiosResponse, Method } from "axios";

import { API } from "./API";
import { APIType } from "./APIType";
import { APISimpleFactory } from "./APISimpleFactory";

import { Log } from "@/util";
import { AuthType } from "../auth/AuthType";
import { AuthSimpleFactory } from "../auth/AuthSimpleFactory";
import { Auth } from "../auth/Auth";

export class ClientAPI {
  private baseURL = "";
  private api!: { type: APIType; instance: API };
  private apiResponse!: AxiosResponse<unknown>;

  public setAuth(type: AuthType): void {
    const auth = {
      type: type,
      instance: AuthSimpleFactory.create(type)
    };

    this.api.instance.setAuth(auth);
  }

  public getAuth(): {
    type: AuthType;
    instance: Auth;
  } {
    return this.api.instance.getAuth();
  }

  public setToken(value: string, expireIn?: number): void {
    this.api.instance.setToken(value, expireIn);
  }

  public getToken(): {
    [key: string]: string;
  } {
    return this.api.instance.getToken();
  }

  public removeToken(): void {
    this.api.instance.removeToken();
  }

  public doesTokenExpire(): boolean {
    return this.api.instance.doesTokenExpire();
  }

  public connect(url: string): void {
    Log.info(url);
  }

  public isConnected(): boolean {
    return false;
  }

  public disconnect(): void {
    Log.info("disconnect");
  }

  public setBaseURL(baseURL: string): void {
    this.baseURL = baseURL;
  }

  public setAPI(type: APIType): void {
    this.api = {
      type: type,
      instance: APISimpleFactory.create(type)
    };
  }

  public async sendAPI(
    uri: string,
    method: Method,
    data?: unknown
  ): Promise<AxiosResponse<unknown>> {
    try {
      await this.callAPI(`${this.baseURL}${uri}`, method, data);
    } catch (error) {
      Log.error(error);
    }

    return this.apiResponse;
  }

  private async callAPI(
    url: string,
    method: Method,
    data?: unknown
  ): Promise<void> {
    this.apiResponse = await this.api.instance.call(url, method, data);
  }

  public async uploadFile(
    uri: string,
    data: FormData
  ): Promise<AxiosResponse<unknown>> {
    try {
      await this.upload(`${this.baseURL}${uri}`, data);
    } catch (error) {
      Log.error(error);
    }

    return this.apiResponse;
  }

  private async upload(url: string, data: FormData): Promise<void> {
    this.apiResponse = await this.api.instance.upload(url, data);
  }

  public async downloadFile(uri: string, data?: unknown): Promise<void> {
    try {
      await this.download(`${this.baseURL}${uri}`, data);
    } catch (error) {
      Log.error(error);
    }
  }

  private async download(url: string, data?: unknown): Promise<void> {
    await this.api.instance.download(url, data);
  }

  public async downloadFileFromS3(url: string, data?: unknown): Promise<void> {
    try {
      await this.downloadFromS3(url, data);
    } catch (error) {
      Log.error(error);
    }
  }

  private async downloadFromS3(url: string, data?: unknown): Promise<void> {
    await this.api.instance.downloadFromS3(url, data);
  }

  public async getImageSrcByPost(uri: string, data?: unknown): Promise<string> {
    let src = "";

    try {
      src = await this.getImageSrc(`${this.baseURL}${uri}`, data);
    } catch (error) {
      Log.error(error);
    }

    return src;
  }

  private async getImageSrc(url: string, data?: unknown): Promise<string> {
    return await this.api.instance.getImageSrc(url, data);
  }
}
