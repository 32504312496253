import { RouteConfig } from "vue-router";

export const ClassRoute: Array<RouteConfig> = [
  {
    path: "/teacher/class",
    component: () => import("@/views/ap/teacher/class/Index.vue")
  },
  {
    path: "/teacher/class/info",
    component: () => import("@/views/ap/teacher/class/Info.vue")
  },
  {
    path: "/teacher/class/student",
    component: () => import("@/views/ap/teacher/class/Student.vue")
  },
];