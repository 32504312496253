import { RouteConfig } from "vue-router";

export const APMainRoute: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("@/views/ap/main/Index.vue")
  },
  {
    path: "/index",
    component: () => import("@/views/ap/main/Index.vue")
  },
  {
    path: "/privacy",
    component: () => import("@/views/ap/main/Privacy.vue")
  },
];
