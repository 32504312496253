import { RouteConfig } from "vue-router";

export const STVideoRoute: Array<RouteConfig> = [
  {
    path: "/student/video",
    component: () => import("@/views/ap/student/video/Index.vue")
  },
  {
    path: "/student/video/info",
    component: () => import("@/views/ap/student/video/Info.vue")
  },
];