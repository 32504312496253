import { RouteConfig } from "vue-router";

export const TeacherRoute: Array<RouteConfig> = [
  {
    path: "/admin/teacher",
    component: () => import("@/views/admin/teacher/Index.vue")
  },
  {
    path: "/admin/teacher/add",
    component: () => import("@/views/admin/teacher/Add.vue")
  },
  {
    path: "/admin/teacher/edit",
    component: () => import("@/views/admin/teacher/Edit.vue")
  }
];