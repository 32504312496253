import { RouteConfig } from "vue-router";

export const STHomeworkRoute: Array<RouteConfig> = [
  {
    path: "/student/homework",
    component: () => import("@/views/ap/student/homework/Index.vue")
  },
  {
    path: "/student/homework/quiz",
    component: () => import("@/views/ap/student/homework/Quiz.vue")
  },
  {
    path: "/student/homework/verify",
    component: () => import("@/views/ap/student/homework/Verify.vue")
  },
  {
    path: "/student/homework/verify/info",
    component: () => import("@/views/ap/student/homework/VerifyInfo.vue")
  },
];