import { RouteConfig } from "vue-router";

export const VideoRoute: Array<RouteConfig> = [
  {
    path: "/admin/video",
    component: () => import("@/views/admin/video/Index.vue")
  },
  {
    path: "/admin/video/add",
    component: () => import("@/views/admin/video/Add.vue")
  },
  {
    path: "/admin/video/edit",
    component: () => import("@/views/admin/video/Edit.vue")
  }
];