import { RouteConfig } from "vue-router";

const InstructionsRoute: Array<RouteConfig> = [
  {
    path: "/admin/instructions",
    component: () => import("@/views/admin/instructions/Index.vue")
  },
  {
    path: "/admin/instructions/edit",
    component: () => import("@/views/admin/instructions/Edit.vue")
  },
];

export { InstructionsRoute };