import { RouteConfig } from "vue-router";

export const STStudentRoute: Array<RouteConfig> = [
  {
    path: "/student/student",
    component: () => import("@/views/ap/student/student/Index.vue"),
  },
  {
    path: "/student/student/edit",
    component: () => import("@/views/ap/student/student/Edit.vue")
  },
];