import { RouteConfig } from "vue-router";

const LoginRoute: Array<RouteConfig> = [
  {
    path: "/teacher",
    component: () => import("@/views/ap/teacher/login/Index.vue")
  },
  {
    path: "/teacher/index",
    component: () => import("@/views/ap/teacher/login/Index.vue")
  },
  {
    path: "/teacher/login",
    component: () => import("@/views/ap/teacher/login/Index.vue")
  },
  {
    path: "/teacher/login/forgetPwd",
    component: () => import("@/views/ap/teacher/login/PasswordForget.vue")
  },
  {
    path: "/teacher/login/resetPwd",
    component: () => import("@/views/ap/teacher/login/PasswordReset.vue")
  }
];

export { LoginRoute };