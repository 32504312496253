import { RouteConfig } from "vue-router";

const LoginRoute: Array<RouteConfig> = [
  {
    path: "/admin",
    component: () => import("@/views/admin/login/Index.vue")
  },
  {
    path: "/admin/index",
    component: () => import("@/views/admin/login/Index.vue")
  },
  {
    path: "/admin/login",
    component: () => import("@/views/admin/login/Index.vue")
  },
];

export { LoginRoute };