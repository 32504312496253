import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { RouterManager } from "./Manager";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

RouterManager.setAllRoute();
routes = routes.concat(RouterManager.getAllRoute());

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export { router };
