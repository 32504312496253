import { RouteConfig } from "vue-router";

import { LoginRoute } from "./Login";
import { AccountRoute } from "./Account";
import { SchoolRoute } from "./school";
import { TeacherRoute } from "./Teacher";
import { StudentRoute } from "./Student";
import { ClassRoute } from "./Class";
import { TextbookRoute } from "./Textbook";
import { QuizRoute } from "./Quiz";
import { HomeworkRoute } from "./Homework";
import { RollcallRoute } from "./Rollcall";
import { VideoRoute } from "./Video";
import { InstructionsRoute } from "./Instructions";

export const AdminRoute: Array<RouteConfig> = [
    ...LoginRoute,
    ...AccountRoute,
    ...SchoolRoute,
    ...TeacherRoute,
    ...StudentRoute,
    ...ClassRoute,
    ...TextbookRoute,
    ...QuizRoute,
    ...HomeworkRoute,
    ...RollcallRoute,
    ...VideoRoute,
    ...InstructionsRoute
];
