import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { router } from "./router";

import "./assets/css/font-awesome.min.css";

import * as protobuf from "protobufjs/minimal";
import * as Long from "long";

import { LangManager, LangType } from "./lang";
import { APIType, Network } from "@/network";

if (window) {
  window.onload = async () => {
    LangManager.setType(process.env.VUE_APP_LANGUAGE as LangType);
    await LangManager.loadMiscLangMap();

    Network.setAPI(APIType.JSON);
    Network.setBaseURL(process.env.VUE_APP_API_BASE_URL as string);

    protobuf.util.Long = Long.default;
    protobuf.configure();

    Vue.config.productionTip = false;

    new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  };
}
